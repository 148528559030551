import styled from "styled-components";
import Bg from "../../assets/img/stakeSectionBg.png";
import RightTick from "../../assets/img/Frame (1).svg";

export const StyledStakeSection = styled.div`
  .staking-title {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 40.8px;
    text-align: left;
  }
  /* background: url(${Bg}) no-repeat center center; */
  background-size: cover;
  background-position: 23% 44%;
  /* height: 100vh; */
  display: flex;
  justify-content: space-around;
  gap: 50px;
  /* padding-left: 79px; */
  padding-top: 54px;
  font-family: Poppins;
  padding-bottom: 100px;
  flex-direction: column;

  .staking-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .disclaimer-info {
      margin-top: 50px;
      border-top: 2px solid #a6c2ce40;

      p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: #3b4856cc;
      }
    }
  }

  @media (max-width: 480px) {
    .staking-wrapper {
      max-width: 400px;
    }
    .stake-btn {
      flex-wrap: wrap;
      justify-content: center !important;
    }
    .claim-btn {
      flex-wrap: wrap;
    }
    .total-stacked {
      flex-wrap: wrap;
      justify-content: center !important;
      gap: 20px;
    }
    .disclaimer-info {
      text-align: justify;
    }
    #sectionundefined {
      text-align: center;
    }
    .ant-table-cell {
      :where().ant-table-wrapper .ant-table-cell,
      :where().ant-table-wrapper .ant-table-thead > tr > th {
        padding: 2px 2px;
      }
    }
    /* .ant-table-wrapper table {
      width: unset !important;
    } */
    .ant-table-wrapper .ant-table-tbody > tr > td {
      padding: 17px 8px;
    }
    .ant-table-wrapper .ant-table-thead > tr > th {
      padding: 17px 8px;
    }
    .ant-table-container table > thead > tr:first-child > *:first-child {
      width: 100px;
    }
  }

  .staking-container {
    width: 80%;
    height: auto;
    border-radius: 14px;
    border: 30px solid #e7e7e7;
    background-color: transparent;
    backdrop-filter: blur(10px);
    /* box-shadow: rgba(0, 103, 153, 0.4) 163px 111px 337px,
      rgba(0, 0, 0, 0) 0px -12px 36px, rgba(2, 0, 0, 0) 0px 4px 6px,
      rgba(0, 0, 0, 0) 0px 12px 13px, rgba(0, 0, 0, 0) 0px -1px 5px; */
  }

  .staking-container-inner {
    .heading {
      text-align: center;
      margin-top: 20px;
      padding-bottom: 32px;
      border-bottom: 2px solid #f3f6fa;
      span {
        color: #000;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 32.64px;
      }
    }
    .stake-content {
      margin: 20px;

      .total-stacked {
        max-width: 100%;
        display: flex;
        padding: 15px 16px 14px 16px;
        justify-content: space-between;
        align-items: flex-start;
        border-radius: 8px;
        border: 1px solid rgba(0, 102, 153, 0.25);
        background: rgba(216, 225, 237, 0.25);
        color: #006699;
        .total-name {
          font-size: 16px;
          font-weight: 500;
        }
        .amount {
          font-size: 18px;
          font-weight: 700;
        }
      }

      .stake-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        gap: 10px;
        .btn-table {
          width: 200px;
        }
      }
    }
  }

  .content-container {
    max-width: 527px;
    margin-right: 50px;

    .heading {
      color: #000;
      font-family: Poppins;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .paragraph {
      color: rgba(59, 72, 86, 0.8);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: justify;
    }
  }

  ul {
    list-style: none;
    li {
      display: flex;
      gap: 10px;
      align-items: center;

      color: rgba(73, 68, 84, 0.8);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .btn {
    margin-top: 50px;
  }

  .token-div {
    width: 209.89px;
    height: 85px;
    flex-shrink: 0;
    background-color: white;
    position: absolute;
    margin-top: -77px;
    left: 410px;
    text-align: center;

    img {
      margin-top: 15px;
    }
    border-radius: 15px;
  }

  @media (max-width: 850px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
    justify-content: space-around;
    gap: 100px;
    margin: 0px 30px;
    padding-left: 0px;

    .staking-container {
    }

    .content-container {
      max-width: 100%;

      .heading {
        text-align: center;
      }

      .btn {
        display: flex;
        justify-content: center;
      }
    }

    .stake-btn {
      .btn-table {
        width: 150px;
      }
    }

    .token-div {
      position: unset;
    }
  }
`;
