import styled from "styled-components";

export const StyledStakingWrapper = styled.div`
  /* background: linear-gradient(to bottom, rgba(0, 123, 255, 0.2), white 0%); */
  background: linear-gradient(
      to bottom,
      rgba(0, 103, 153, 0.15) 0%,
      /* Lighter blue at the very top */ rgba(255, 255, 255, 0.8) 20%
        /* Transitioning quickly to white */
    ),
    radial-gradient(
      circle at 50% 10%,
      /* Positioned closer to the top center */ rgba(0, 103, 153, 0.15) 10%,
      /* Smaller and lighter blue circle */ rgba(0, 103, 153, 0) 30%
    );
  padding: 70px;
  .intro-section {
    h2 {
      font-family: Poppins;
      font-size: 56px;
      font-weight: 700;
      line-height: 84px;
      text-align: center;
    }
    p {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
      color: #3b4856cc;
    }
  }
  .rules-section {
    h2 {
      font-family: Poppins;
      font-size: 40px;
      font-weight: 600;
      line-height: 40.8px;
      text-align: center;
    }
    h3 {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 600;
      line-height: 24.48px;
      text-align: left;
    }
    p {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #3b4856cc;
    }
  }

  @media (max-width: 480px) {
    padding: unset;

    .intro-section {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      h2 {
        font-size: 36px;
      }
      p {
        /* max-width: 400px; */
        margin: 0px 20px;
      }
    }
    .rules-section {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      p {
        /* max-width: 394px; */
        text-align: justify;
      }
    }
    .info-section {
      margin: 0px 20px 0px 30px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  @media (max-width: 850px) {
    justify-content: center;
  }
`;

export const Content = styled.div`
  margin-left: 420px;
  padding: 20px;
  @media (max-width: 850px) {
    margin-left: unset;
    padding: unset;
  }
`;

export const Section = styled.div`
  padding: 20px;
  @media (max-width: 480px) {
    padding: unset;
  }
`;
