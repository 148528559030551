import { useCallback, useEffect, useState } from "react";
import * as anchor from "@coral-xyz/anchor";
import { Buffer } from "buffer";
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  getAssociatedTokenAddressSync,
} from "@solana/spl-token";
import {
  useAnchorWallet,
  useConnection,
  useWallet,
} from "@solana/wallet-adapter-react";
import idl from "../constants/Abi/crd-staking-idl.json";
import {
  openNotificationWithIcon,
  sendVersionedTransactionWithComputeUnits,
} from "../helper/helper";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../store/loadingSlice";
// import { SYSTEM_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/native/system";

const poolMint = new anchor.web3.PublicKey(
  "9fPWJYCDMx3DBCqEpvTKiffQbXg7eyrFrifAJUJRKohP"
);

window.Buffer = Buffer;
export const useStakingProgram = () => {
  const { publicKey } = useWallet();
  const wallet = useAnchorWallet();
  const { connection } = useConnection();
  const [initialized, setInitialized] = useState(false);
  const [userSolBalance, setUserSolBalance] = useState(0);

  const fetchSolBalance = useCallback(async () => {
    if (!publicKey) return;
    const balance = await connection.getBalance(publicKey);
    setUserSolBalance(balance / LAMPORTS_PER_SOL);
  }, [connection, publicKey]);

  const provider = new anchor.AnchorProvider(connection, wallet);
  const program = new anchor.Program(idl, provider);

  console.log("walletUseStakingProgram", provider);
  // let balanceInSol;
  // if (publicKey) {
  //   const balanceInLamports = connection.getBalance(publicKey);
  //   balanceInSol = balanceInLamports / LAMPORTS_PER_SOL;
  // }
  // console.log("balanceInSol-----", balanceInSol);

  useEffect(() => {
    console.log(
      "publickKey,wallet from useEffect",
      publicKey,
      "wallllet0",
      wallet
    );
    if (publicKey && wallet) {
      setInitialized(true);
      fetchSolBalance();
    } else {
      setInitialized(false);
    }
  }, [publicKey, wallet]);
  console.log("initialized after rendering", initialized);

  // const balanceInSol = fetchSolBalance();

  console.log("balanceInSol", userSolBalance);

  const dispatch = useDispatch();

  const executeTransaction = async (ix) => {
    dispatch(setIsLoading(true));
    try {
      console.log("🚀 ~ ix:", ix);
      const txnHash = await sendVersionedTransactionWithComputeUnits(provider, [
        ix,
      ]);
      console.log("🚀 ~ txnHash:", txnHash);
      // openNotificationWithIcon("success", `Transaction successful`);
      return txnHash;
    } catch (error) {
      console.error("Transaction failed:", error);
      openNotificationWithIcon("error", `Transaction failed: ${error}`);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const deposit = useCallback(
    async (amount) => {
      if (!initialized) {
        openNotificationWithIcon("error", `Please connect your wallet`);
        return;
      }
      if (userSolBalance <= 0) {
        openNotificationWithIcon("error", `Insufficient SOL balance`);
        return;
      }
      try {
        const STAKE_SEED = Buffer.from(
          anchor.utils.bytes.utf8.encode("crd-swap-stake")
        );
        const POOL_SEED = Buffer.from(
          anchor.utils.bytes.utf8.encode("crd-swap-pool")
        );
        const STAKE_PDA_SEED = Buffer.from(
          anchor.utils.bytes.utf8.encode("crd-stake-pda")
        );

        // Find program addresses
        const [poolAccount] = anchor.web3.PublicKey.findProgramAddressSync(
          [POOL_SEED, poolMint.toBuffer(), poolMint.toBuffer()],
          program.programId
        );

        const [stakeAccount] = anchor.web3.PublicKey.findProgramAddressSync(
          [STAKE_SEED, publicKey.toBuffer(), poolAccount.toBuffer()],
          program.programId
        );

        const [programPda] = anchor.web3.PublicKey.findProgramAddressSync(
          [STAKE_PDA_SEED, poolMint.toBuffer()],
          program.programId
        );
        const poolTokenAccount = getAssociatedTokenAddressSync(
          poolMint,
          poolAccount,
          true
        );

        const userPoolTokenAccount = getAssociatedTokenAddressSync(
          poolMint,
          publicKey
        );

        const ix = await program.methods
          .deposit(
            new anchor.BN((Number(amount) * LAMPORTS_PER_SOL).toFixed(0))
          )
          .accounts({
            stakeAccount,
            poolAccount,
            poolMint,
            poolTokenAccount,
            userPoolTokenAccount,
            user: publicKey,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          })
          .instruction();

        const txnHash = await executeTransaction(ix);
        return txnHash;
      } catch (error) {
        console.error("Error depositing:", error);
        openNotificationWithIcon("error", `Transaction failed: ${error}`);
        throw error;
      }
    },
    [initialized, program, publicKey, provider]
  );

  const withdraw = useCallback(
    async (amount) => {
      if (!initialized) {
        openNotificationWithIcon("error", `Please connect your wallet`);
        return;
      }
      if (userSolBalance <= 0) {
        openNotificationWithIcon("error", `Insufficient SOL balance`);
        return;
      }
      try {
        const STAKE_SEED = Buffer.from(
          anchor.utils.bytes.utf8.encode("crd-swap-stake")
        );
        const POOL_SEED = Buffer.from(
          anchor.utils.bytes.utf8.encode("crd-swap-pool")
        );
        const STAKE_PDA_SEED = Buffer.from(
          anchor.utils.bytes.utf8.encode("crd-stake-pda")
        );

        // Find program addresses
        const [poolAccount] = anchor.web3.PublicKey.findProgramAddressSync(
          [POOL_SEED, poolMint.toBuffer(), poolMint.toBuffer()],
          program.programId
        );

        const [stakeAccount] = anchor.web3.PublicKey.findProgramAddressSync(
          [STAKE_SEED, publicKey.toBuffer(), poolAccount.toBuffer()],
          program.programId
        );

        const [programPda] = anchor.web3.PublicKey.findProgramAddressSync(
          [STAKE_PDA_SEED, poolMint.toBuffer()],
          program.programId
        );

        // Calculate associated token addresses
        const poolTokenAccount = getAssociatedTokenAddressSync(
          poolMint,
          poolAccount,
          true
        );

        const userPoolTokenAccount = getAssociatedTokenAddressSync(
          poolMint,
          publicKey
        );

        console.log("token-account", poolTokenAccount.toBase58());

        console.log("Amount-test", (amount * LAMPORTS_PER_SOL).toFixed(0));
        const ix = await program.methods
          .withdraw(new anchor.BN((amount * LAMPORTS_PER_SOL).toFixed(0)))
          .accounts({
            stakeAccount,
            poolAccount,
            poolMint,
            poolTokenAccount,
            userPoolTokenAccount,
            user: publicKey,
            tokenProgram: TOKEN_PROGRAM_ID,
          })
          .instruction();

        const txnHash = await executeTransaction(ix);
        return txnHash;
      } catch (error) {
        console.error("Error withdrawing:", error);
        openNotificationWithIcon("error", `Transaction failed: ${error}`);
        throw error;
      }
    },
    [initialized, program, publicKey, provider]
  );

  const claimReward = useCallback(async () => {
    try {
      const STAKE_SEED = Buffer.from(
        anchor.utils.bytes.utf8.encode("crd-swap-stake")
      );
      const POOL_SEED = Buffer.from(
        anchor.utils.bytes.utf8.encode("crd-swap-pool")
      );
      const STAKE_PDA_SEED = Buffer.from(
        anchor.utils.bytes.utf8.encode("crd-stake-pda")
      );

      // Find program addresses
      const [poolAccount] = anchor.web3.PublicKey.findProgramAddressSync(
        [POOL_SEED, poolMint.toBuffer(), poolMint.toBuffer()],
        program.programId
      );

      const [stakeAccount] = anchor.web3.PublicKey.findProgramAddressSync(
        [STAKE_SEED, publicKey.toBuffer(), poolAccount.toBuffer()],
        program.programId
      );

      const [programPda] = anchor.web3.PublicKey.findProgramAddressSync(
        [STAKE_PDA_SEED, poolMint.toBuffer()],
        program.programId
      );

      // Calculate associated token addresses
      const poolTokenAccount = getAssociatedTokenAddressSync(
        poolMint,
        poolAccount,
        true
      );

      const userPoolTokenAccount = getAssociatedTokenAddressSync(
        poolMint,
        publicKey
      );

      const ix = await program.methods
        .claimReward()
        .accounts({
          stakeAccount,
          poolAccount,
          rewardMint: poolMint,
          rewardTokenAccount: poolTokenAccount,
          userRewardTokenAccount: userPoolTokenAccount,
          user: publicKey,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: new PublicKey("11111111111111111111111111111111"),
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        })
        .instruction();

      const txnHash = await executeTransaction(ix);
      return txnHash;
    } catch (error) {
      console.error("Error withdrawing:", error);
      openNotificationWithIcon("error", `Transaction failed: ${error}`);
      throw error;
    }
  }, [initialized, program, publicKey, provider]);

  const fetchStakeAccountData = useCallback(async () => {
    console.log("inititalized from fetchStakeAccount Data", initialized);
    console.log("Holaa", publicKey);
    console.log("Hola wallet", wallet);

    if (!(publicKey && wallet)) {
      return null;
    }
    try {
      const STAKE_SEED = Buffer.from(
        anchor.utils.bytes.utf8.encode("crd-swap-stake")
      );

      const POOL_SEED = Buffer.from(
        anchor.utils.bytes.utf8.encode("crd-swap-pool")
      );
      const [poolAccount] = anchor.web3.PublicKey.findProgramAddressSync(
        [POOL_SEED, poolMint.toBuffer(), poolMint.toBuffer()],
        program.programId
      );
      const [stakeAccount] = anchor.web3.PublicKey.findProgramAddressSync(
        [STAKE_SEED, publicKey.toBuffer(), poolAccount.toBuffer()],
        program.programId
      );
      const stakeAccountData = await program.account.stakeAccount.fetch(
        stakeAccount
      );

      console.log("stakeAccountData", stakeAccountData);
      return stakeAccountData;
    } catch (error) {
      console.error("Error fetching stake account data:", error);
      throw error;
    }
  }, [initialized, publicKey, wallet, program]);

  return {
    deposit,
    withdraw,
    claimReward,
    fetchStakeAccountData,
  };
};

export default useStakingProgram;
