import React from "react";
import { StyledKeyFeatures } from "./styles";
import ImgKeyFeature1 from "../../assets/img/Frame 482625.svg";
import ImgKeyFeature2 from "../../assets/img/Frame 482826.svg";
import ImgKeyFeature3 from "../../assets/img/Frame 482828.svg";

const keyFeaturesData = [
  {
    title: "Blockchain Security",
    description:
      "Ensure the authenticity and integrity of your certificates with blockchain technology.",
    img: ImgKeyFeature1,
  },
  {
    title: "User-Friendly Interface",
    description:
      "A simple and intuitive platform to manage all your certification needs.",
    img: ImgKeyFeature2,
  },
  {
    title: "Efficient Process",
    description:
      "Mint certificates quickly and efficiently, saving time and resources.",
    img: ImgKeyFeature3,
  },
];

const KeyFeatures = () => {
  return (
    <StyledKeyFeatures>
      <div className="heading">
        <span>Why Choose Us?</span>
      </div>
      <div className="cards-collection">
        {keyFeaturesData.map((item, index) => (
          <div key={index} className="card-container">
            <div className="card-img">
              <img src={item.img} alt="" />
            </div>
            <div className="card-title">
              <span>{item.title}</span>
            </div>
            <div className="card-description">
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </StyledKeyFeatures>
  );
};

export default KeyFeatures;
