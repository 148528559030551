import React from "react";
import certificateImg from "../../../assets/img/certificate-image1 (1).png";


const CertificateComponent = () => {
  return (
    <div className="certificate-component">
      <img src={certificateImg} alt="certificate-image" />
    </div>
  );
};

export default CertificateComponent;
