export const IntroSection = () => {
  return (
    <section id="section1" className="intro-section">
      <h2>Introduction to  <br/>CertDigital Staking</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur. Sollicitudin mauris integer
        suscipit condimentum donec. Quis non a tellus commodo lacus cursus sit
        ipsum euismod. Mollis sit feugiat tellus sit lacus fermentum scelerisque
        integer. Convallis in enim malesuada orci quis vitae.Lorem ipsum dolor
        sit amet consectetur. Sollicitudin mauris integer suscipit condimentum
        donec. Quis non a tellus commodo lacus cursus sit ipsum euismod. Mollis
        sit feugiat tellus sit lacus fermentum scelerisque integer. Convallis in
        enim malesuada orci quis vitae.
      </p>
    </section>
  );
};
