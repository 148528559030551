import React, { useState } from "react";
import { MintCertificateSectionStyled } from "./style";
import HowItWorksSection from "./components/HowItWorksSection";
import CertificateComponent from "./components/CertificateComponent";
import { CreateButton } from "../../styles/globleStyles";
import ModalForApplyInstitute from "../ModalForApplyInstitute/ModalForApplyInstitute";

const MintCertificateSection = () => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <MintCertificateSectionStyled>
        <div className="content-container">
          <CertificateComponent />
          <HowItWorksSection />
        </div>
        <div className="cert-btn">
          <CreateButton onClick={() => setModalVisible(true)}>
            Start your Digitalization
          </CreateButton>
        </div>
      </MintCertificateSectionStyled>
      <ModalForApplyInstitute
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </>
  );
};

export default MintCertificateSection;
