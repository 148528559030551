import { Modal } from "antd";
import styled from "styled-components";

export const StyledCustomModal = styled(Modal)`
  .ant-modal-close {
    top: 30px !important;
    right: 30px !important;
  }
  .ant-modal-content {
    border-radius: 14px;
    border: 2px solid #e7e7e7;
    backdrop-filter: blur(2px);
    max-width: ${(props) => props.width || "50%"};
    padding: 25px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 103, 153, 0.4) 0px 6px 45px,
      rgba(0, 0, 0, 0) 0px -12px 36px, rgba(0, 0, 0, 0) 0px 4px 6px,
      rgba(0, 0, 0, 0) 0px 12px 13px, rgba(0, 0, 0, 0) 0px -1px 5px;
    border: 1px solid grey;
    border: 2px solid #e7e7e7 !important;
    background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);

    border-radius: 14px;

    @media (max-width: 768px) {
      width: 100%;
    }
    .note {
      color: #069;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .ant-modal-body {
      max-width: 100% !important;
      height: 100% !important;
      background: #fff;
      border-radius: 14px;
      width: 100%;
      height: 100%;

      .title {
        border-bottom: 2px solid #f3f6fa;
        text-align: center;
        h2 {
          /* padding: 20px 20px; */
          color: #000;
          font-family: Poppins;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 102%; /* 32.64px */
        }
      }
      .child-container {
        /* padding: 10px 30px; */
        .button-container {
          display: flex;
          gap: 25px;
          margin-top: 20px;
          /* margin-bottom: 35px; */
        }
        .amount-info {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 1px solid rgba(0, 102, 153, 0.25);
          background: rgba(216, 225, 237, 0.25);
          color: #069;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          height: 45px;
        }
        .mint-container {
          .mint-info {
            display: flex;
            justify-content: space-between;
            color: #777e91;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          .total {
            color: #000;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
        .input-container {
          .amount-title {
            margin-top: 24px;
            margin-bottom: 8px;
          }
          p {
            color: #777e91;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 0;
          }
          .ant-input-number-input {
            height: 45px;
            color: #3b4856;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            box-shadow: none;
            outline: none;
            &:hover {
              border-color: #d9d9d9 !important;
            }
            &:focus,
            &:active {
              border: none !important;
              outline: none !important;
            }
          }
          input:hover {
            border-color: #d9d9d9 !important;
          }
          .ant-input-number-focused {
            border-color: #d9d9d9 !important;
            &:hover {
              border-color: #d9d9d9 !important;
            }
          }
        }
      }
    }
  }
`;
