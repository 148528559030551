import { useEffect, useState } from "react";
import { Modal } from "antd";
import useStakingProgram from "./useStakingProgram";
import { openNotificationWithIcon } from "../helper/helper";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../store/loadingSlice";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";

const useFetchStakeAccountData = () => {
  const { publicKey } = useWallet();
  const wallet = useAnchorWallet();
  const [stackAmountDetails, setStackAmountDetails] = useState(null);
  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { fetchStakeAccountData } = useStakingProgram();
  const dispatch = useDispatch();

  const fetchData = async (param) => {
    dispatch(setIsLoading(true));
    setError(null); // Reset error state before fetching
    try {
      const data = await fetchStakeAccountData();
      setStackAmountDetails(data);
      console.log("Data------<<<<<<.>>>>>>>>", data);
    } catch (e) {
      setError(e.message || "An unknown error occurred");
      if (param !== "FETCH") {
        openNotificationWithIcon(
          "error",
          `Please stake CRD to fetch the staked CRD Balance!`
        );
      }
    }
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    fetchData("FETCH");
  }, [wallet, publicKey]);
  const handleOk = () => {
    setError(null); // Clear the error state when the modal is closed
  };

  return { stackAmountDetails, fetchData, error, handleOk };
};

export default useFetchStakeAccountData;
