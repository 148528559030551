import styled from "styled-components";

export const StyledKeyFeatures = styled.div`
  padding-bottom: 150px;
  .heading {
    text-align: center;
    span {
      color: #332c49;
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    margin-bottom: 60px;
  }

  .cards-collection {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 20px;
    max-width: 100%;
    margin: 0px 20px;
  }
  .card-container {
    width: 100%;
    height: 296px;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 40px;
    border: 2px solid #fff;
    padding: 20px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(2px);
    box-shadow: rgba(0, 103, 153, 0.4) 0px 6px 45px,
      rgba(0, 0, 0, 0) 0px -12px 36px, rgba(0, 0, 0, 0) 0px 4px 6px,
      rgba(0, 0, 0, 0) 0px 12px 13px, rgba(0, 0, 0, 0) 0px -1px 5px;

    .card-title {
      span {
        color: #000;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 33.6px;
        margin-top: 30px;
      }
    }
    .card-description {
      p {
        color: rgba(59, 72, 86, 0.8);
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
      }
    }
  }

  @media (max-width: 850px) {
    .cards-collection {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  @media (min-width: 850px) and (max-width: 1100px) {
    .card-container .card-title span {
      font-size: 18px;
    }
  }
`;
