import styled from "styled-components";

export const StyledHeaderComponent = styled.div`
  width: auto;
  height: 54px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 16px 29px 0px 30px;
  border-bottom: 4px solid #f3f6fa;

  .btn-container {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    height: 40px;
    width: auto;
  }

  .wallet-adapter-button-trigger {
    background-color: #0d0d0d !important;
  }

  .wallet-adapter-button {
    background-color: #0d0d0d !important;
  }
  @media (max-width: 480px) {
    .btn-container {
      width: 170px !important;
    }
  }
`;
