/* eslint-disable use-isnan */
import React, { useEffect, useState } from "react";
import { StyledStakeSection } from "./styles";
import { CommonButton, CreateButton } from "../../styles/globleStyles";
import CRDReward from "../../assets/img/crdReward.png";
import { ConfigProvider, Table, Alert, Button, Tooltip } from "antd"; // Import Alert component from antd
import useStakingProgram from "../../hook/useStakingProgram";
import CustomModal from "../Modal";
import { Connection, LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import useFetchStakeAccountData from "../../hook/useFetchStakeAccountData";
import { useWallet } from "@solana/wallet-adapter-react";
import { ReloadOutlined } from "@ant-design/icons";

const StakeSection = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const { publicKey, connected } = useWallet();
  const [modalTitle, setModalTitle] = useState("Enter your amount");
  const [isStakeModal, setIsStakeModal] = useState(true);
  const { deposit, withdraw } = useStakingProgram();
  const { stackAmountDetails, loading, fetchData } = useFetchStakeAccountData();

  const handleStake = async (amount) => {
    console.log("Staking amount:", amount);
  };

  const handleDeposit = async (amount) => {
    try {
      console.log("Performing deposit with amount:", amount);
      const tx = await deposit(amount);
      console.log("Deposit transaction:", tx);
      fetchData();
    } catch (error) {
      console.error("Failed to deposit:", error);
    }
  };

  const handleWithdraw = async (amount) => {
    try {
      console.log("Performing withdraw with amount:", amount);
      const tx = await withdraw(amount);
      console.log("Withdraw transaction:", tx);
      fetchData();
    } catch (error) {
      console.error("Failed to withdraw:", error);
    }
  };

  const handleOpenStakeModal = () => {
    setModalTitle("Stake your CRD Token");
    setIsStakeModal(true);
    setModalVisible(true);
  };

  const handleOpenUnStakeModal = () => {
    setModalTitle("Unstake your CRD Token");
    setIsStakeModal(false);
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleStakeAndDeposit = async (amount) => {
    await handleStake(amount);
    await handleDeposit(amount);
  };

  const handleUnStakeAndWithdraw = async (amount) => {
    await handleWithdraw(amount);
  };

  const columns = [
    {
      title: (
        <>
          <div style={{ width: 100, whiteSpace: "nowrap", textAlign: "start" }}>
            Staked Amt."
          </div>
        </>
      ),
      dataIndex: "StakedAmt",
      key: "StakedAmt",
      width: 100,
    },
    {
      title: "Rewards",
      dataIndex: "Rewards",
      key: "Rewards",
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
    },
  ];

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: "#fff",
              borderColor: "rgba(159, 173, 189, 0.30)",
              headerSplitColor: "transparent",
              colorText: "#3B4856",
              colorTextHeading: "#777E91",
            },
          },
        }}
      >
        <StyledStakeSection>
          <div className="token-div">
            <img src={CRDReward} alt="" />
          </div>
          <div className="staking-container">
            <div className="staking-container-inner">
              <div className="heading">
                <span>Staking</span>
              </div>
              <div className="stake-content">
                <div className="total-stacked">
                  <span className="total-name">Total CRD</span>
                  <span className="amount">
                    {(
                      Number(stackAmountDetails?.depositAmount.toString()) /
                        LAMPORTS_PER_SOL +
                      Number(stackAmountDetails?.reward?.toString()) /
                        LAMPORTS_PER_SOL
                    ).toString() !== "NaN"
                      ? (
                          Number(stackAmountDetails?.depositAmount.toString()) /
                            LAMPORTS_PER_SOL +
                          Number(stackAmountDetails?.reward.toString()) /
                            LAMPORTS_PER_SOL
                        ).toString()
                      : 0}
                    &nbsp;CRD
                  </span>
                  <Button onClick={fetchData}>
                    <ReloadOutlined />
                  </Button>
                </div>
                <div>
                  {stackAmountDetails ? (
                    <Table
                      dataSource={[
                        {
                          key: stackAmountDetails.authority.toBase58(),
                          StakedAmt: (
                            Number(
                              stackAmountDetails.depositAmount.toString()
                            ) / LAMPORTS_PER_SOL
                          ).toString(),
                          Rewards: (
                            Number(stackAmountDetails.reward.toString()) /
                            LAMPORTS_PER_SOL
                          ).toString(),
                          Total: (
                            Number(
                              stackAmountDetails?.depositAmount.toString()
                            ) /
                              LAMPORTS_PER_SOL +
                            Number(stackAmountDetails?.reward.toString()) /
                              LAMPORTS_PER_SOL
                          ).toString(),
                        },
                      ]}
                      columns={columns}
                      pagination={false}
                      // scroll={{ y: 240 }}
                    />
                  ) : (
                    <Alert
                      message="Initialize Your Account"
                      description="No staking details found. Please initialize your account."
                      type="info"
                      showIcon
                    />
                  )}
                </div>
                <div className="stake-btn">
                  <div className="btn-table">
                    {connected ? (
                      <CommonButton
                        onClick={handleOpenStakeModal}
                        primary
                        width="100%"
                      >
                        Stake
                      </CommonButton>
                    ) : (
                      <Tooltip
                        placement="bottom"
                        title={"Please connect your wallet !"}
                      >
                        <CommonButton
                          onClick={handleOpenStakeModal}
                          primary
                          width="100%"
                          disabled={!connected}
                        >
                          Stake
                        </CommonButton>
                      </Tooltip>
                    )}
                  </div>
                  <div className="btn-table">
                    {connected ? (
                      <CommonButton
                        onClick={handleOpenUnStakeModal}
                        primary
                        width="100%"
                      >
                        Unstake
                      </CommonButton>
                    ) : (
                      <Tooltip
                        placement="bottom"
                        title={"Please connect your wallet !"}
                      >
                        <CommonButton
                          onClick={handleOpenUnStakeModal}
                          primary
                          width="100%"
                          disabled={!connected}
                        >
                          Unstake
                        </CommonButton>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-container">
            <div className="heading">
              <span>Stake your CRD Tokens</span>
            </div>
            <div className="paragraph">
              <p>
                Lorem ipsum dolor sit amet consectetur. Sollicitudin mauris
                integer suscipit condimentum donec. Quis non a tellus commodo
                lacus cursus sit ipsum euismod. Mollis sit feugiat tellus sit
                lacus fermentum scelerisque integer. Convallis in enim malesuada
                orci quis vitae.
              </p>
            </div>
            <div>
              <ul>
                <li>
                  <img src="https://i.postimg.cc/SNydCHWR/Frame-1.png" alt="" />
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Sollicitudin mauris
                    integer suscipit condimentum donec.
                  </p>
                </li>
                <li>
                  <img src="https://i.postimg.cc/SNydCHWR/Frame-1.png" alt="" />
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Sollicitudin mauris
                    integer suscipit condimentum donec.
                  </p>
                </li>
                <li>
                  <img src="https://i.postimg.cc/SNydCHWR/Frame-1.png" alt="" />
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Sollicitudin mauris
                    integer suscipit condimentum donec.
                  </p>
                </li>
              </ul>
            </div>
            <div className="btn">
              {!connected ? (
                <Tooltip
                  placement="bottom"
                  title={"Please connect your wallet !"}
                >
                  <CreateButton
                    disabled={!connected}
                    onClick={handleOpenStakeModal}
                  >
                    Stake your CRD Tokens
                  </CreateButton>
                </Tooltip>
              ) : (
                <CreateButton onClick={handleOpenStakeModal}>
                  Stake your CRD Tokens
                </CreateButton>
              )}
            </div>
          </div>
        </StyledStakeSection>
      </ConfigProvider>
      <CustomModal
        visible={modalVisible}
        onCancel={handleCancel}
        onStakeAndDeposit={handleStakeAndDeposit}
        onUnstakeAll={handleUnStakeAndWithdraw}
        title={modalTitle}
        action={isStakeModal ? "stake" : "unstake"}
        amount={stackAmountDetails?.depositAmount?.toString()}
        total={(
          Number(stackAmountDetails?.depositAmount.toString()) /
            LAMPORTS_PER_SOL +
          Number(stackAmountDetails?.reward.toString()) / LAMPORTS_PER_SOL
        ).toString()}
      />
    </>
  );
};

export default StakeSection;
