import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../config";

export const Apis = createApi({
  reducerPath: "apis",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.BASE_URL,
  }),
  tagTypes: [],
  endpoints: () => ({}),
});
