export const RulesSection = ({ rulesSectionData,id }) => {
  return (

    
    <section id={`section${id}`} className="rules-section">
      <h2>{rulesSectionData.heading}</h2>
      {rulesSectionData.info.map((item,index)=>{
        return (
            <div key={index} className="info-section">
            <h3>{item?.infoTitle}</h3>
            <p>
              {item.infoDesc}
            </p>
          </div>
        )
      })}
    
    </section>
  );
};
