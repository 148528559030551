import React from "react";
import { ReactComponent as CardImage } from "../../../assets/img/signup-image.svg";

const GetStartCard = ({
  cardNumber,
  cardHeading,
  cardDescription,
  cardImageComponent,
}) => {
  return (
    <div className="get-start-card">
      <div className="arrow-right">
        <span className="card-number">{cardNumber}</span>
      </div>
      <div className="internal-card">
        <div className="internal-card-content">
          <div className="card-img">{cardImageComponent}</div>
          <div className="card-text">
            <span className="card-heading">{cardHeading}</span>
            <p className="card-description">{cardDescription}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStartCard;
