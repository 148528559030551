import { notification } from "antd";
import * as anchor from "@coral-xyz/anchor";

export default function formatEthereumAddress(address) {
  const firstPart = address.slice(0, 7);
  const lastPart = address.slice(-7);

  const formattedAddress = `${firstPart}.....${lastPart}`;

  return formattedAddress;
}

export const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message,
    description,
    duration: 3,
  });
};

export async function sendVersionedTransactionWithComputeUnits(
  provider,
  instructions
) {
  console.log("🚀 ~ instructions:", instructions);
  console.log("proider", provider.wallet.publicKey.toBase58());

  // console.log({
  //   payerKey: provider.wallet.publicKey,
  //   instructions: [...instructions],
  //   recentBlockhash: await provider.connection
  //     .getLatestBlockhash("confirmed")
  //     .then((data) => data.blockhash),
  // });
  let messageV0 = new anchor.web3.TransactionMessage({
    payerKey: provider.wallet.publicKey,
    instructions: [...instructions],
    recentBlockhash: await provider.connection
      .getLatestBlockhash("confirmed")
      .then((data) => data.blockhash),
  }).compileToV0Message();

  const simTransaction = new anchor.web3.VersionedTransaction(messageV0);
  const transaction = anchor.web3.VersionedTransaction.deserialize(
    simTransaction.serialize()
  );

  const simulation = await provider.connection.simulateTransaction(
    transaction,
    {
      sigVerify: false,
    }
  );

  const unitLimit = !simulation.value.err
    ? simulation?.value?.unitsConsumed * 1.1 || 400_000
    : 400_000;

  const LIMIT_FEE_IX = anchor.web3.ComputeBudgetProgram.setComputeUnitLimit({
    units: unitLimit,
  });

  const PRIORITY_FEE_IX = anchor.web3.ComputeBudgetProgram.setComputeUnitPrice({
    microLamports: 1000000,
  });

  messageV0 = new anchor.web3.TransactionMessage({
    payerKey: provider.wallet.publicKey,
    instructions: [LIMIT_FEE_IX, PRIORITY_FEE_IX, ...instructions],
    recentBlockhash: await provider.connection
      .getLatestBlockhash("confirmed")
      .then((data) => data.blockhash),
  }).compileToV0Message();

  const txn = new anchor.web3.VersionedTransaction(messageV0);
  console.log("🚀 ~ sendTransaction ~ txn:", txn);

  await provider.wallet.signTransaction(txn);
  const transactionHash = await provider.sendAndConfirm(txn);
  console.log("🚀 Transaction hash:", transactionHash);
  return transactionHash;
}
