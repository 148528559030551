import { useSelector } from "react-redux";
import FooterComponent from "../../components/Footer";
import HeaderComponent from "../../components/Header";
import HeroSection from "../../components/HeroSection";
import KeyFeatures from "../../components/KeyFeatures";
import StakeSection from "../../components/StakeSection";
import Spinner from "../../components/spinner/Spinner";
import { StyledHomeWrapper } from "./style";
import GetStartCards from "../../components/GetStartCards";
import MintCertificateSection from "../../components/MintCertificateSection";
import SubscriptionSection from "../../components/SubscriptionSection/SubscriptionSection";
import Staking from "../Staking";

export const Home = () => {
  const isLoading = useSelector((state) => state.loader.isLoading);
  console.log("🚀 ~ Home ~ isLoading:", isLoading);

  return (
    <>
      <StyledHomeWrapper>
        <HeroSection />
        <KeyFeatures />
        <MintCertificateSection />

        <GetStartCards />
        <SubscriptionSection />
      </StyledHomeWrapper>
      {isLoading && <Spinner />}
    </>
  );
};
