import { StyledCustomModal } from "./style";

const CustomModal = ({ visible, onClose, title, children, width }) => {
  return (
    <StyledCustomModal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={width}
    >
      <div className="ant-modal-body">
        <div className="title">
          <h2>{title}</h2>
        </div>
        {children}
      </div>
    </StyledCustomModal>
  );
};

export default CustomModal;
