import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import useStakingProgram from "../../../hook/useStakingProgram";
import useFetchStakeAccountData from "../../../hook/useFetchStakeAccountData";
import { Alert, Button, ConfigProvider, Table, Tooltip } from "antd";
import { StyledStakeSection } from "../../../components/StakeSection/styles";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { ReloadOutlined } from "@ant-design/icons";
import { CommonButton, CreateButton } from "../../../styles/globleStyles";
import CustomModal from "../../../components/Modal";
import CRDReward from "../../../assets/img/crdReward.png";
import { useStakeAndUnStakeMutation } from "../../../services/createapi";
import { openNotificationWithIcon } from "../../../helper/helper";
import Spinner from "../../../components/spinner/Spinner";
import { useSelector } from "react-redux";
import useTokenBalance from "../../../hook/useTokenBalance";

const StakeTableSection = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const { publicKey, connected } = useWallet();
  const [modalTitle, setModalTitle] = useState("Enter your amount");
  const [isStakeModal, setIsStakeModal] = useState(true);
  const { deposit, withdraw, claimReward } = useStakingProgram();
  const { stackAmountDetails, loading, fetchData } = useFetchStakeAccountData();
  const { solBalance } = useTokenBalance();

  const [stakeAndUnStake, { isLoading }] = useStakeAndUnStakeMutation();

  const walletAddress = publicKey ? publicKey.toBase58() : null;

  const isLoadingSpinner = useSelector((state) => state.loader.isLoading);

  const handleStake = async (amount) => {
    console.log("Staking amount:", amount);
  };

  console.log("solBalance", solBalance);

  const handleDeposit = async (amount) => {
    try {
      console.log("Performing deposit with amount:", amount);
      const response = await deposit(amount);
      console.log("response-------->>>>>", response);
      if (response) {
        const result = await stakeAndUnStake({
          walletAddress: walletAddress,
          amount: amount,
          type: "STAKE",
          transactionHash: response,
        }).unwrap();

        console.log("result----", result);
        openNotificationWithIcon("success", `Transaction successful`);
      }
      fetchData();
    } catch (error) {
      console.error("Failed to deposit:", error);
    }
  };

  const handleWithdraw = async (amount) => {
    try {
      console.log("Performing withdraw with amount:", amount);
      const response = await withdraw(amount);
      console.log("response-------->>>>>", response);
      if (response) {
        const result = await stakeAndUnStake({
          walletAddress: walletAddress,
          amount: amount,
          type: "UNSTAKE",
          transactionHash: response,
        }).unwrap();
        console.log("result----", result);
        openNotificationWithIcon("success", `Transaction successful`);
      }
      fetchData();
    } catch (error) {
      console.error("Failed to withdraw:", error);
    }
  };

  const handleClaim = async () => {
    try {
      const tx = await claimReward();
      console.log("Deposit transaction:", tx);
      if (tx) {
        openNotificationWithIcon(
          "success",
          `The reward has been successfully claimed.`
        );
      }

      fetchData();
    } catch (error) {
      console.error("Failed to deposit:", error);
    }
  };

  const handleOpenStakeModal = () => {
    setModalTitle("Stake your CRD Token");
    setIsStakeModal(true);
    setModalVisible(true);
  };

  const handleOpenUnStakeModal = () => {
    setModalTitle("Unstake your CRD Token");
    setIsStakeModal(false);
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleStakeAndDeposit = async (amount) => {
    await handleStake(amount);
    await handleDeposit(amount);
  };

  const handleUnStakeAndWithdraw = async (amount) => {
    await handleWithdraw(amount);
  };

  const columns = [
    {
      title: "Staked Amt.",
      dataIndex: "StakedAmt",
      key: "StakedAmt",
    },
    {
      title: "Rewards",
      dataIndex: "Rewards",
      key: "Rewards",
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    console.log(stackAmountDetails, "connected ");
  }, [stackAmountDetails]);

  const rewards = (
    Number(stackAmountDetails?.reward?.toString()) / LAMPORTS_PER_SOL
  ).toFixed(6);

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: "#fff",
              borderColor: "rgba(159, 173, 189, 0.30)",
              headerSplitColor: "transparent",
              colorText: "#3B4856",
              colorTextHeading: "#777E91",
            },
          },
        }}
      >
        <StyledStakeSection>
          <h3 className="staking-title">Let's stake</h3>

          <div className="staking-wrapper">
            <div className="staking-container">
              <div className="staking-container-inner">
                <div className="heading">
                  <span>Staking</span>
                </div>
                <div className="stake-content">
                  <div className="total-stacked">
                    <span className="total-name">Total CRD</span>
                    <span className="amount">
                      {(
                        Number(stackAmountDetails?.depositAmount.toString()) /
                          LAMPORTS_PER_SOL +
                        Number(stackAmountDetails?.reward?.toString()) /
                          LAMPORTS_PER_SOL
                      ).toString() !== "NaN"
                        ? (
                            Number(
                              stackAmountDetails?.depositAmount.toString()
                            ) /
                              LAMPORTS_PER_SOL +
                            Number(stackAmountDetails?.reward.toString()) /
                              LAMPORTS_PER_SOL
                          ).toString()
                        : 0}
                      &nbsp;CRD
                    </span>
                    <Button onClick={fetchData}>
                      <ReloadOutlined />
                    </Button>
                  </div>
                  <div>
                    {stackAmountDetails ? (
                      <Table
                        tailor={false}
                        dataSource={[
                          {
                            key: stackAmountDetails.authority.toBase58(),
                            StakedAmt: (
                              Number(
                                stackAmountDetails.depositAmount.toString()
                              ) / LAMPORTS_PER_SOL
                            ).toString(),
                            // Rewards: (
                            //   Number(stackAmountDetails.reward.toString()) /
                            //   LAMPORTS_PER_SOL
                            // ).toString(),
                            Rewards: rewards,
                            Total: (
                              Number(
                                stackAmountDetails?.depositAmount.toString()
                              ) /
                                LAMPORTS_PER_SOL +
                              Number(stackAmountDetails?.reward.toString()) /
                                LAMPORTS_PER_SOL
                            ).toString(),
                          },
                        ]}
                        columns={columns}
                        pagination={false}
                        scroll={{ y: 240 }}
                      />
                    ) : (
                      <Alert
                        message={
                          connected
                            ? "Initialize Your Account"
                            : "Please connect to your wallet"
                        }
                        description={
                          connected
                            ? "No staking details found. Please initialize your account."
                            : ""
                        }
                        type="info"
                        showIcon
                        style={{ marginTop: 20 }}
                      />
                    )}
                  </div>
                  <div className="stake-btn">
                    <div className="btn-table">
                      {connected ? (
                        <CommonButton
                          onClick={handleOpenStakeModal}
                          primary
                          width="100%"
                        >
                          Stake
                        </CommonButton>
                      ) : (
                        <Tooltip
                          placement="bottom"
                          title={"Please connect your wallet !"}
                        >
                          <CommonButton
                            onClick={handleOpenStakeModal}
                            primary
                            style={{ width: "100%" }}
                            disabled={!connected}
                          >
                            Stake
                          </CommonButton>
                        </Tooltip>
                      )}
                    </div>
                    <div className="btn-table">
                      {connected ? (
                        <div
                          className="claim-btn"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",

                            gap: 10,
                          }}
                        >
                          <CommonButton
                            onClick={handleOpenUnStakeModal}
                            primary
                            borderRadius="4px"
                            width="100%"
                          >
                            Unstake
                          </CommonButton>
                          <CommonButton
                            borderRadius="4px"
                            bordered="#006699"
                            color="#006699"
                            onClick={handleClaim}
                            width="100%"
                          >
                            Claim Rewards
                          </CommonButton>
                        </div>
                      ) : (
                        <Tooltip
                          placement="bottom"
                          title={"Please connect your wallet !"}
                        >
                          <CommonButton
                            onClick={handleOpenUnStakeModal}
                            primary
                            width="100%"
                            disabled={!connected}
                          >
                            Unstake
                          </CommonButton>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="disclaimer-info">
              <p>
                Disclaimer: The content provided on this website is for
                informational purposes only and does not constitute investment
                advice. The information provided is not intended to be a
                substitute for professional financial advice, consultation, or
                recommendations. Users are encouraged to consult with a
                qualified financial advisor before making any investment
                decisions. The website owners and authors do not assume any
                liability for any loss or damage that may result from reliance
                on the information provided. All investments carry risk, and
                past performance is not indicative of future results.
              </p>
            </div>
          </div>
        </StyledStakeSection>
      </ConfigProvider>
      <CustomModal
        visible={modalVisible}
        onCancel={handleCancel}
        onStakeAndDeposit={handleStakeAndDeposit}
        onUnstakeAll={handleUnStakeAndWithdraw}
        title={modalTitle}
        action={isStakeModal ? "stake" : "unstake"}
        amount={stackAmountDetails?.depositAmount?.toString()}
        total={(
          Number(stackAmountDetails?.depositAmount.toString()) /
          LAMPORTS_PER_SOL
        ).toString()}
      />
      {(isLoadingSpinner || isLoading) && <Spinner />}
    </>
  );
};

export default StakeTableSection;
