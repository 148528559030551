import React from "react";
import Logo from "../../assets/img/CertdigitalLogo.png";
import { StyledHeaderComponent } from "./styles";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { CommonButton, CreateButton } from "../../styles/globleStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const HeaderComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "-----Rishab Sharma-------");

  useEffect(() => {}, []);
  const handleLoginClick = () => {
    window.location.href = "http://3.221.198.94:3002/login";
  };

  return (
    <StyledHeaderComponent>
      <div
        className="logo-container"
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
      >
        <img src={Logo} alt="" />
      </div>
      <div className="btn-container">
        {location?.pathname === "/" && (
          <CommonButton
            onClick={() => navigate("/stake")}
            primary
            width="150px"
            height="48px"
            borderRadius="4px"
            style={{ fontSize: 18 }}
          >
            {/* {location?.pathname === "/" ? "Staking" : "Home"} */}
            Staking
          </CommonButton>
        )}
        {location?.pathname === "/stake" ? (
          <WalletMultiButton />
        ) : (
          <CommonButton
            width="150px"
            height="48px"
            borderRadius="4px"
            bordered="#006699"
            color="#006699"
            onClick={handleLoginClick}
          >
            Login
          </CommonButton>
        )}
      </div>
    </StyledHeaderComponent>
  );
};

export default HeaderComponent;
