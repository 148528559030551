import React from "react";
import { useNavigate } from "react-router-dom";
import { StyledFooterComponent } from "./styles";
import Facebook from "../../assets/img/facebook_white.png";
import Instagram from "../../assets/img/instagram.png";
import Twitter from "../../assets/img/twitter.png";
import LinkedIn from "../../assets/img/linkedin.png";
import Logo from "../../assets/img/CertdigitalLogo.png";

const FooterComponent = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <StyledFooterComponent>
      <div className="footer-container">
        <div className="left">
          <div>
            <img src={Logo} alt="" />
          </div>
          <div>
            <div className="social-links">
              <a
                href="https://www.facebook.com/certdigitalcom"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Facebook} alt="Facebook" />
              </a>
              <a
                href="https://www.instagram.com/certdigitalcom/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Instagram} alt="Instagram" />
              </a>
              <a
                href="https://x.com/CertDigital_"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Twitter} alt="Twitter" />
              </a>
              <a
                href="https://www.linkedin.com/in/cert-digital-7925a030a/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LinkedIn} alt="LinkedIn" />
              </a>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="company">
            <span
              onClick={() => handleNavigation("/")}
              style={{ cursor: "pointer" }}
            >
              Home
            </span>
            <span
              onClick={() => handleNavigation("/stake")}
              style={{ cursor: "pointer" }}
            >
              Staking
            </span>
          </div>
          <div className="company">
            <span
              onClick={() => handleNavigation("/privacy-policy")}
              style={{ cursor: "pointer" }}
            >
              Privacy Policy
            </span>
            <span
              onClick={() => handleNavigation("/terms-and-conditions")}
              style={{ cursor: "pointer" }}
            >
              Terms and Conditions
            </span>
          </div>
        </div>
      </div>
      <div className="footer-term-and-condition">
        <div className="copyright">
          <img src="/images/copyRight.svg" alt="" />
          <p className="font-poppins500 font-grey">2024 CertDigital</p>
        </div>
        <div className="copyright">
          <img src="/images/dotIcon.svg" alt="" />
          <p className="font-poppins500 font-grey">All rights reserved</p>
        </div>
      </div>
    </StyledFooterComponent>
  );
};

export default FooterComponent;
