import React from "react";
import styled from "styled-components";

const Card = styled.div`
  position: fixed;
  left: 0;
  top: 20%;
  width: 300px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 40px;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 15px 0px #00669914;
  display: flex;
  align-items: start;
  padding: 15px;
  margin-left: 45px;
  flex-direction: column;

  @media (max-width: 850px) {
    display: none;
  }
`;

const Heading = styled.div`
  cursor: pointer;
  color: ${(props) => (props.isActive ? "#006699" : "#3B4856B2")};
  font-weight: ${(props) => (props.isActive ? 600 : 500)};
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 16.32px;
  text-align: left;
  padding: 10px 4px;
  text-align: left;
  margin: 20px;
  margin-left: 15px;
  width: 85%;
  border-bottom: 2px solid #a6c2ce40;

  &:hover {
    color: #006699;
  }
`;

const FixedCard = ({ sections, activeSection, onHeadingClick }) => {
  return (
    <Card>
      {sections.map((section) => (
        <Heading
          key={section.id}
          isActive={section.id === activeSection}
          onClick={() => onHeadingClick(section.id)}
        >
          {section.title}
        </Heading>
      ))}
    </Card>
  );
};

export default FixedCard;
