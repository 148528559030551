import { Modal } from "antd";
import styled from "styled-components";

export const CustomModalStyled = styled(Modal)`
  .ant-modal-close {
    top: 30px !important;
    right: 30px !important;
  }
  .ant-modal-content {
    border-radius: 14px;
    border: 2px solid #e7e7e7;
    backdrop-filter: blur(2px);
    padding: 25px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 103, 153, 0.4) 0px 6px 45px,
      rgba(0, 0, 0, 0) 0px -12px 36px, rgba(0, 0, 0, 0) 0px 4px 6px,
      rgba(0, 0, 0, 0) 0px 12px 13px, rgba(0, 0, 0, 0) 0px -1px 5px;
    border: 1px solid grey;
    padding: 25px 25px;
    border: 2px solid #e7e7e7 !important;
    background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);

    border-radius: 14px;
    .note {
      color: #069;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .ant-modal-body {
      width: 100% !important;
      height: 100% !important;
      background: #fff;
      border-radius: 14px;
      width: 100%;
      height: 100%;

      .title {
        border-bottom: 2px solid #f3f6fa;
        h2 {
          /* padding: 20px 20px; */
          color: #000;
          font-family: Poppins;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 102%; /* 32.64px */
        }
      }

      .ant-row-middle {
        margin: 10px 10px;
        justify-content: space-between;
      }

      .ant-col {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .child-container {
        padding: 30px 30px;
      }
      .button-container {
        display: flex;
        justify-content: space-between;
        margin: 50px 10px 10px 10px;
        gap: 10px;
      }
      .ant-input-outlined {
        width: 445px;
        height: 50px;
      }
      .reload {
        cursor: pointer;
      }
    }

    @media (max-width: 480px) {
      .ant-input-outlined {
        width: 343px !important;
      }
    }
  }
`;
