import React from "react";
import { SubscriptionSectionStyled } from "./style";
import { ReactComponent as SubscriptionIcon } from "../../assets/img/subscriptionIcon.svg";
import { ReactComponent as PayAsYouGoIcon } from "../../assets/img/payAsYouGoIcon.svg";

const SubscriptionSection = () => {
  return (
    <SubscriptionSectionStyled>
      <div className="header-container">
        <span className="heading">Mint Certificate</span>
        <p className="description">
          Lorem ipsum dolor sit amet consectetur. Sollicitudin mauris integer
          suscipit condimentum donec. Quis non a tellus commodo lacus cursus sit
          ipsum euismod. Mollis sit feugiat tellus sit lacus fermentum
          scelerisque integer. Convallis in enim malesuada orci quis vitae.
        </p>
      </div>
      <div className="subscription-container">
        <div className="select-subscription-container">
          <div className="option-card">
            <div className="icon-wrapper">
              <PayAsYouGoIcon />
            </div>
            <div className="card-content">
              <span className="option-title">Pay As You Go</span>
              <p className="option-description">
                Lorem ipsum dolor sit amet consectetur. Sollicitudin mauris
                integer suscipit condimentum donec.
              </p>
            </div>
          </div>

          <div className="option-card">
            <div className="icon-wrapper">
              <SubscriptionIcon />
            </div>
            <div className="card-content-one">
              <span className="option-title">Monthly Subscription</span>
              <p className="option-description">
                Lorem ipsum dolor sit amet consectetur. Sollicitudin mauris
                integer suscipit condimentum donec.
              </p>
            </div>
          </div>
        </div>
      </div>
    </SubscriptionSectionStyled>
  );
};

export default SubscriptionSection;
