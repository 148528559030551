import styled from "styled-components";

export const GetStartCardStyled = styled.div`
  /* display: flex;
  justify-content: center; */
  margin-top: 117px;

  /* .cards {
    display: flex;
    justify-content: center;
  } */
  .heading {
    text-align: center;
    margin-bottom: 50px;
    span {
      font-family: Poppins;
      font-size: 40px;
      font-weight: 700;
      line-height: 40.8px;
    }
  }
  .card-container {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    gap: 20px;
    /* max-width: 1280px; */
    align-items: center;
    margin: 20px;
  }
  .get-start-card {
    width: 100%;
    .arrow-right {
      position: relative;
      left: 90px;
      top: -10px;
      --r: 20px; /* border radius */

      height: 180px;
      /* width: 200px; Adjust width to ensure it is visible */
      display: flex; /* Use flexbox to center content */
      align-items: center; /* Vertically center */
      justify-content: center; /* Horizontally center */
      aspect-ratio: cos(30deg);
      --_g: calc(tan(60deg) * var(--r)) left var(--r), #000 98%, #0000 101%;
      -webkit-mask: conic-gradient(
            from 60deg at calc(3 * var(--r) / 2 - 100%),
            #000 60deg,
            #0000 0
          )
          0 0 / calc(100% - 3 * var(--r) / 2) 100% no-repeat,
        radial-gradient(
          var(--r) at calc(100% - 2 * var(--r)) 50%,
          #000 98%,
          #0000 101%
        ),
        radial-gradient(var(--r) at top var(--_g)),
        radial-gradient(var(--r) at bottom var(--_g));
      clip-path: polygon(100% 50%, 0 100%, 0 0);
      background: #006699;
      overflow: hidden;

      .card-number {
        position: relative;
        left: -17px;
        top: -12px;
        font-family: Poppins;
        font-size: 32px;
        font-weight: 700;
        line-height: 32.64px;
        text-align: left;
        color: white;
      }
    }

    .internal-card {
      width: 100%;
      height: 394px;
      border: 2px solid;

      border-image-source: linear-gradient(
        165.4deg,
        #ffffff -10.07%,
        rgba(255, 255, 255, 0) 89.61%
      );
      box-shadow: rgba(0, 103, 153, 0.4) 0px 6px 45px,
        rgba(0, 0, 0, 0) 0px -12px 36px, rgba(0, 0, 0, 0) 0px 4px 6px,
        rgba(0, 0, 0, 0) 0px 12px 13px, rgba(0, 0, 0, 0) 0px -1px 5px;

      /* background-color: transparent; */
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.7),
          rgba(255, 255, 255, 0.7)
        ),
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0) 100%
        );

      backdrop-filter: blur(5px);
      position: relative;
      top: -100px;
      border-radius: 40px;
    }
    .internal-card-content {
      min-width: 226px;
      height: 219px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 70px;
      gap: 16px;
    }

    .card-text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;
    }
    .card-heading {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 500;
      line-height: 33.6px;
      text-align: center;
    }
    .card-description {
      width: 226px;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: center;
      color: #3b4856cc;
    }
  }

  @media (max-width: 850px) {
    .card-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .get-start-card .arrow-right {
      left: 312px;
    }
  }

  @media (max-width: 480px) {
    .get-start-card .arrow-right {
      left: 136px;
    }
  }

  /* 
  @media (min-width: 850px) and (max-width: 1100px) {
    .card-container .card-title span {
      font-size: 18px;
    }
  } */
`;
