import React from "react";
import { GetStartCardStyled } from "./style";
import GetStartCard from "./components/GetStartCard";
import { ReactComponent as CardImage } from "../../assets/img/signup-image.svg";
import { ReactComponent as SubscriptionIcon } from "../../assets/img/subscriptionGetStartIcon.svg";
import { ReactComponent as FeeIcon } from "../../assets/img/feeIcon.svg";
import { ReactComponent as MintIcon } from "../../assets/img/MintIcon.svg";

const cardData = [
  {
    cardNumber: "01",
    cardHeading: "Sign Up",
    cardDescription: "Create an account for your institute on our platform.",
    cardImageComponent: <CardImage />,
  },
  {
    cardNumber: "02",
    cardHeading: "Choose a Subscription",
    cardDescription: "Select a subscription plan that matches your needs.",
    cardImageComponent: <SubscriptionIcon />,
  },
  {
    cardNumber: "03",
    cardHeading: "Start Mint",
    cardDescription: "Begin minting certificates for your students with ease.",
    cardImageComponent: <MintIcon />,
  },
  {
    cardNumber: "04",
    cardHeading: "Fee",
    cardDescription: "Stake your CRD tokens to activate your account.",
    cardImageComponent: <FeeIcon />,
  },
];

const GetStartCards = () => {
  return (
    <GetStartCardStyled>
      <div className="heading">
        <span>Getting Started</span>
      </div>
      <div className="cards">
        <div className="card-container">
          {cardData.map((card, index) => (
            <GetStartCard
              key={index}
              cardNumber={card.cardNumber}
              cardHeading={card.cardHeading}
              cardDescription={card.cardDescription}
              cardImageComponent={card.cardImageComponent}
            />
          ))}
        </div>
      </div>
    </GetStartCardStyled>
  );
};

export default GetStartCards;
