import { Apis } from "./api";

export const createApi = Apis.injectEndpoints({
  endpoints: (builder) => ({
    onBoardInstitution: builder.mutation({
      query: (body) => ({
        url: `/onboard-institute`,
        method: "POST",
        body,
      }),
    }),
    stakeAndUnStake: builder.mutation({
      query: (body) => ({
        url: `/create-staking-history`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useOnBoardInstitutionMutation, useStakeAndUnStakeMutation } =
  createApi;
