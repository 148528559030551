import styled from "styled-components";

export const StyledFooterComponent = styled.div`
  max-width: 100%;
  font-family: Poppins;
  width: 100%;
  position: absolute;

  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    margin: 0 50px;

    padding-bottom: 42px;
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .social-links {
      display: flex;
      gap: 16px;
    }
  }

  .right {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    gap: 50px;

    .company {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .company-span {
        color: #000;
        font-size: 18px;
        font-weight: 600;
        line-height: 18.36px;
      }

      span {
        color: #3b4856;
        font-size: 16px;
        font-weight: 400;
        line-height: 16.32px;
      }
    }
  }

  .footer-term-and-condition {
    background: #006699;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: white;
    .copyright {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      img {
        margin-left: 10px;
      }
    }
  }

  @media (max-width: 850px) {
    .footer-container {
      flex-wrap: wrap;
      justify-content: center;
      gap: 50px;
    }
  }

  @media (max-width: 480px) {
    .right {
      /* flex-wrap: wrap; */
      justify-content: center;
      /* align-items: center; */
    }
  }
`;
