import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import FixedCard from "./components/FixedCard";
import { IntroSection } from "./components/IntroSection";
import { Container, Content, Section, StyledStakingWrapper } from "./styles";
import { RulesSection } from "./components/RulesSection";
import StakeTableSection from "./components/StakeSection";

const sections = [
  { id: "section1", title: "Introduction to Staking" },
  { id: "section2", title: "Staking Rules" },
  { id: "section3", title: "Reward Calculation" },
  { id: "section4", title: "Let’s Stake" },
];

const rulesSectionData = {
  heading: "Staking Rules",
  info: [
    {
      infoTitle: "1. Staking",
      infoDesc:
        "Lorem ipsum dolor sit amet consectetur. Sollicitudin mauris integer suscipit condimentum donec. Quis non a tellus commodo lacus cursus sit ipsum euismod. Mollis sit feugiat tellus sit lacus fermentum scelerisque integer.",
    },
    {
      infoTitle: "2. Redeeming",
      infoDesc:
        "Lorem ipsum dolor sit amet consectetur. Sollicitudin mauris integer suscipit condimentum donec. Quis non a tellus commodo lacus cursus sit ipsum euismod. Mollis sit feugiat tellus sit lacus fermentum scelerisque integer.",
    },
    {
      infoTitle: "3. Annual Percentage Rate (APR)",
      infoDesc:
        "Lorem ipsum dolor sit amet consectetur. Sollicitudin mauris integer suscipit condimentum donec. Quis non a tellus commodo lacus cursus sit ipsum euismod. Mollis sit feugiat tellus sit lacus fermentum scelerisque integer.",
    },
  ],
};

const rewardCalculationData = {
  heading: "Reward Calculation",
  info: [
    {
      infoTitle: "1. Source",
      infoDesc:
        "Lorem ipsum dolor sit amet consectetur. Sollicitudin mauris integer suscipit condimentum donec. Quis non a tellus commodo lacus cursus sit ipsum euismod. Mollis sit feugiat tellus sit lacus fermentum scelerisque integer.",
    },
    {
      infoTitle: "2. Calculation of Est. Reward",
      infoDesc:
        "Lorem ipsum dolor sit amet consectetur. Sollicitudin mauris integer suscipit condimentum donec. Quis non a tellus commodo lacus cursus sit ipsum euismod. Mollis sit feugiat tellus sit lacus fermentum scelerisque integer.",
    },
  ],
};

const Staking = () => {
  const [activeSection, setActiveSection] = useState("section1");
  const sectionRefs = useRef({});
  const isClickingRef = useRef(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (isClickingRef.current) return;

      const scrollTop = window.scrollY + window.innerHeight / 2;
      let currentSection = "section1";

      for (const sectionId in sectionRefs.current) {
        const section = sectionRefs.current[sectionId];
        const rect = section.getBoundingClientRect();
        if (rect.top <= scrollTop && rect.bottom >= scrollTop) {
          currentSection = sectionId;
          break;
        }
      }

      setActiveSection(currentSection);
    };

    const debouncedHandleScroll = debounce(handleScroll, 50);

    window.addEventListener("scroll", debouncedHandleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, []);

  const handleHeadingClick = (sectionId) => {
    isClickingRef.current = true;
    setActiveSection(sectionId);

    window.scrollTo({
      top: sectionRefs.current[sectionId].offsetTop - 20,
      behavior: "smooth",
    });

    setTimeout(() => {
      isClickingRef.current = false;
    }, 500);
  };

  return (
    <StyledStakingWrapper>
      <Container>
        <FixedCard
          sections={sections}
          activeSection={activeSection}
          onHeadingClick={handleHeadingClick}
        />
        <Content>
          <Section ref={(el) => (sectionRefs.current["section1"] = el)}>
            <IntroSection />
          </Section>
          <Section ref={(el) => (sectionRefs.current["section2"] = el)}>
            <RulesSection rulesSectionData={rulesSectionData} />
          </Section>
          <Section ref={(el) => (sectionRefs.current["section3"] = el)}>
            <RulesSection rulesSectionData={rewardCalculationData} />
          </Section>
          <Section ref={(el) => (sectionRefs.current["section4"] = el)}>
            <StakeTableSection />
          </Section>
        </Content>
      </Container>
    </StyledStakingWrapper>
  );
};

export default Staking;

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}
