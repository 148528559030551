import MainSectionWrapper from "./components/mainSectionWrapper";
// import { WalletProviderContext } from "./context/walletConnectContext";

function App() {
  return (
    <>
      <MainSectionWrapper />
    </>
  );
}

export default App;
