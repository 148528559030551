import styled from "styled-components";

import Bg from "../../assets/img/certificateImage.png";

export const StyledHeroSection = styled.div`
  background: url(${Bg}) no-repeat center center;
  /* background-size: cover; */
  background-position: 235px -113px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 858px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  .content-container {
    margin: 87px 0px 0px 118px;
  }
  .heading {
    color: #000;
    font-family: Poppins;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    p {
      color: #006699;
      margin-left: 15px;
      margin: 0px;
    }
  }

  .paragraph {
    color: rgba(59, 72, 86, 0.8);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    max-width: 648px;
    text-align: justify;
  }

  .btn-container {
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  @media (max-width: 850px) {
    background: none;

    height: auto;
    .content-container {
      text-align: center;
      padding-bottom: 100px;
      margin: 20px 20px;
    }

    .btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      max-width: unset;
    }

    .paragraph {
      max-width: 100%;
    }
  }
`;
