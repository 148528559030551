import React, { useState } from "react";
import { CreateButton } from "../../../styles/globleStyles";
import ModalForApplyInstitute from "../../ModalForApplyInstitute/ModalForApplyInstitute";

const HowItWorksSection = () => {
  return (
    <>
      <div className="how-it-works-section">
        <div>
          <div className="heading ">
            <span>How It Works</span>
          </div>
          <div>
            <div className="sub-heading">Purchase CRD Tokens:</div>
            <div className="paragraph">
              <p>
                Institutes must purchase our native CRD tokens to utilize the
                certification services. CRD tokens are the key to accessing our
                platform’s features.
              </p>
            </div>
          </div>
          <div>
            <div className="sub-heading">Stake and Unstake CRD Tokens:</div>
            <ul>
              <li>
                Stake CRD Tokens:
                <p>
                  By staking CRD tokens, institutes can unlock the ability to
                  mint certificates. The staking process is straightforward,
                  ensuring that your tokens are securely held while you use our
                  services.
                </p>
              </li>
              <li>
                Unstake CRD Tokens:
                <p>
                  Institutes can unstake their tokens at any time, providing
                  flexibility in managing your resources.
                </p>
              </li>
            </ul>
          </div>
          <div>
            <div className="sub-heading">Choose Your Subscription Plan:</div>
            <div className="paragraph">
              <p>
                Based on your needs, select a subscription plan that suits your
                institute. Each plan offers a different level of access and
                features, ensuring that you only pay for what you need.
              </p>
            </div>
          </div>
          <div>
            <div className="sub-heading">Mint Certificates:</div>
            <div className="paragraph">
              <p>
                Once you have purchased and staked CRD tokens, and selected your
                subscription plan, you can start minting certificates. Our
                platform guides you through the process, ensuring each
                certificate is securely issued and easily verifiable.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorksSection;
